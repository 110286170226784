import { memo } from 'react';
import { useMedias } from '@divlab/divanui';

import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import useTranslation from '@Queries/useTranslation';
import FilterSelectedValues from '@Components/FilterSelectedValues';
import Labels from '../common/Labels';
import PopularLinks from '../common/PopularLinks';
import QuickFilters from '../QuickFilters';
import styles from './DefaultFilters.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { PopularLinkData } from '@Types/Category';
import type { GroupData } from '../typings';
import type { FilterView } from '@Types/Filters';

export interface FiltersProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  count?: number;
  groups?: GroupData[];
  popularLinks?: PopularLinkData[];
  onOpen?: (e: MouseEvent, id: string, filterView: FilterView) => void;
}

const DefaultFilters: FC<FiltersProps> = (props) => {
  const { className, count, groups = [], popularLinks = [], onOpen, ...restProps } = props;
  const { t } = useTranslation();
  const { isOnlyDesktop } = useMedias();
  const filtrator = CatalogFiltrator.useFiltrator();
  const priorityFilters = CatalogFiltrator.usePriorityFilters();
  const appliedValues = CatalogFiltrator.useAppliedValues();

  return (
    <div {...restProps} className={className}>
      <div className={styles.filtersWrapper}>
        <div className={styles.actions}>
          {filtrator.filters && <QuickFilters priorityFilters={priorityFilters} onOpen={onOpen} />}
          {typeof count === 'number' && isOnlyDesktop && (
            <div className={styles.count}>
              {t('ui.filters.found')}
              <span className='ProductRange'>{count}</span>
            </div>
          )}
        </div>
        {filtrator.sort.length > 0 && (
          <div className={styles.wrapperLabels}>
            <Labels groups={groups} filterView='default' />
          </div>
        )}
      </div>

      {appliedValues.length > 0 && (
        <FilterSelectedValues
          className={styles.selectedValues}
          values={appliedValues}
          filtratorId='catalog'
        />
      )}

      {popularLinks.length > 0 && (
        <div className={styles.bottom}>
          <div>
            <PopularLinks label={t('ui.filters.popular')} popularLinks={popularLinks} />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(DefaultFilters);
