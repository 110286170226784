import { memo, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import { isAbsoluteLink } from '@Utils/isAbsoluteLink';
import styles from './PopularLinks.module.css';

import type { PopularLinkData } from '@Types/Category';
import type { FC, HTMLAttributes } from 'react';

export interface PopularLinksProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  label?: string;
  popularLinks: PopularLinkData[];
}

const PopularLinks: FC<PopularLinksProps> = (props) => {
  const { className, label, popularLinks, ...restProps } = props;

  // TODO: backend
  // Некоторые ссылки абсолютные и ведут на домен divan.ru
  // Поэтому приходится преобразовывать их и убирать домен, чтобы ссылки корректно работали на localhost, в песочницах и тд
  const links = useMemo(() => {
    return popularLinks.map((popularLink) => {
      if (!isAbsoluteLink(popularLink.link)) return popularLink;

      const url = new URL(popularLink.link);

      return { ...popularLink, link: `${url.pathname}${url.search}` };
    });
  }, [popularLinks]);

  return (
    <div {...restProps} className={cn(styles.popularLinks, className)}>
      <div className={styles.container}>
        {label && <span className={styles.label}>{label}</span>}
        {links.map((popularLink) => (
          <Link
            key={popularLink.name}
            className={cn(styles.link, 'TagLink')}
            to={popularLink.link}
            view='primary'
            underlined
          >
            {popularLink.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(PopularLinks);
