import { memo } from 'react';
import { cn, GalleryWithProgress } from '@divlab/divanui';

import Item from './Item';
import styles from './Subcategories.module.css';

import type { FC } from 'react';
import type { SubcategoryData } from '@Types/Category';

export interface SubcategoriesProps {
  className?: string;
  subcategories?: SubcategoryData[];
}

const Subcategories: FC<SubcategoriesProps> = (props) => {
  const { className, subcategories = [], ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.subcategories, className)}>
      <GalleryWithProgress
        className={styles.gallery}
        cnTrack={styles.track}
        cnGallery={styles.itemsWrapper}
        centered
        emptyTrack
      >
        {subcategories.map((subcategory) => (
          <div key={`${subcategory.id}-${subcategory.link}`} className={styles.galleryItem}>
            <Item subcategory={subcategory} />
          </div>
        ))}
      </GalleryWithProgress>
    </div>
  );
};

export default memo(Subcategories);
